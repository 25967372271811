<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Opération réussie.</span>
      <v-btn text color="white" @click="snackbar = false">Fermer</v-btn>
    </v-snackbar>

    <v-snackbar v-model="snackbarCreate" :timeout="4000" top color="success">
      <span>L'inscription a bien été créée</span>
      <v-btn text color="white" @click="snackbarCreate = false">Fermer</v-btn>
    </v-snackbar>

    <v-dialog v-model="dialogStudent">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">{{ dialogStudentTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-alert v-if="error" type="error">
                <ul class="alert alert-danger">
                  <li v-for="(errorD, index) in error.details" :key="index">{{ errorD[0] }}</li>
                </ul>
              </v-alert>
              <v-col cols="12" sm="12" md="12">
                <v-row>
                  <v-col cols="12" sm="2" md="2">
                    <v-select v-model="editedItem.gender" :items="genders" label="Genre*" item-text="name" item-value="key" :rules="nameRules"></v-select>
                  </v-col>
                  <v-col cols="12" sm="5" md="5">
                    <v-text-field v-model="editedItem.last_name" label="Nom*" hide-details="auto" :rules="nameRules"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="5" md="5">
                    <v-text-field v-model="editedItem.first_name" label="Prénom*" hide-details="auto" :rules="nameRules"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.birth_date" label="Date de naissance*" hide-details="auto" :rules="nameRules"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea v-model="editedItem.health_situation" label="Etat de santé" rows="2"></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea v-model="editedItem.comments" label="Remarques" rows="2"></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="close">{{$t('actions.cancel')}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="saveStudent">{{$t('actions.save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTutor">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">{{ dialogTutorTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-alert v-if="error" type="error">
                <ul class="alert alert-danger">
                  <li v-for="(errorD, index) in error.details" :key="index">{{ errorD[0] }}</li>
                </ul>
              </v-alert>
              <v-col cols="12" sm="12" md="12">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.last_name" label="Nom*" hide-details="auto" :rules="nameRules"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.first_name" label="Prénom*" hide-details="auto" :rules="nameRules"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.mobile" label="Mobile*" hide-details="auto" :rules="nameRules"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.email" :label="$t('email')" hide-details="auto" ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">{{$t('actions.cancel')}}</v-btn>
          <v-btn color="blue darken-1" text @click="save">{{$t('actions.save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-stepper v-model="e1" v-if="!this.inscriptionFinished" alt-labels>
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" editable step="1">{{tutorLabelStep}}</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 2" editable step="2">Élèves</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 3" editable step="3">Classes</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 4" editable step="4">Paiement</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card>
            <v-card-title>
              <v-autocomplete v-model="tutor" :loading="loading" :items="items" :search-input.sync="search" clearable item-text="name" item-value="id" class="mx-4" flat hide-details label="Nom" placeholder="Recherche" prepend-icon="mdi-account-search" return-object solo-inverted loader-height="5">
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item @click="addTutor">Ajouter tuteur</v-list-item>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">{{ item.last_name }} {{ item.first_name }}</template>
              </v-autocomplete>
            </v-card-title>

            <v-expand-transition>
              <v-row>
                <v-card v-if="tutor" class="mx-auto mt-10 mb-5">
                  <v-card-title class="pl-12">
                    <div class="display-1 pl-12 pt-2">
                      {{tutor.name}}
                      <!-- <v-chip class="ma-2" color="teal" text-color="white" label>
                        <v-avatar left>
                          <v-icon>mdi-checkbox-marked-circle</v-icon>
                        </v-avatar>En ordre de paiement
                      </v-chip> -->
                    </div>
                  </v-card-title>

                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">mdi-phone</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{tutor.mobile}}</v-list-item-title>
                        <v-list-item-subtitle>Mobile</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider inset></v-divider>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">mdi-email</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{tutor.email}}</v-list-item-title>
                        <v-list-item-subtitle>Personnel</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider inset></v-divider>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">mdi-bank</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>+++{{tutor.banking_communication}}+++</v-list-item-title>
                        <v-list-item-subtitle>Communication structurée</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-card-actions>
                    <v-btn text color="deep-purple accent-4" @click="editItem(tutor)">Modifier</v-btn>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-expand-transition>
            <v-card-actions class="mt-5">
              <v-btn text>{{$t('actions.cancel')}}</v-btn>
              <v-spacer></v-spacer>

              <v-btn color="primary" @click="nextStep(1)">{{$t('actions.next')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card>
            <v-card-title>
              <v-autocomplete v-model="student" :loading="loading" :items="studentsSearched" :search-input.sync="search2" clearable item-text="name" item-value="id" class="mx-4" flat hide-details label="Nom" placeholder="Recherche" prepend-icon="mdi-database-search" return-object solo-inverted loader-height="5">
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item @click="addStudent">Ajouter élève</v-list-item>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">{{ item.last_name }} {{ item.first_name }}</template>
              </v-autocomplete>
            </v-card-title>

            <v-expand-transition>
              <v-row>
                <v-card v-if="student" class="mx-auto mt-10 mb-5">
                  <v-card-title class="">
                    <div class="display-1 pt-2">
                      {{student.name}}
                      <!-- <v-chip class="ma-2" color="teal" text-color="white" label>
                        <v-avatar left>
                          <v-icon>mdi-checkbox-marked-circle</v-icon>
                        </v-avatar>En ordre de paiement
                      </v-chip> -->
                    </div>
                  </v-card-title>

                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">mdi-cake</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{formatedDate( student.birth_date)}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider inset></v-divider>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">mdi-clipboard-text</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{student.health_situation}}</v-list-item-title>
                        <v-list-item-subtitle>Etat de santé</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider inset></v-divider>
                    <v-list-item v-if="student.comments">
                      <v-list-item-icon>
                        <v-icon color="indigo">mdi-text-box</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{student.comments}}</v-list-item-title>
                        <v-list-item-subtitle>Remarques</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-card-actions>
                    <v-btn text right color="deep-purple accent-4" @click="addStudentToTutor">Ajouter</v-btn>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-expand-transition>

            <v-data-table :headers="headers" :items="students" :loading="loading" item-key="id" class="elevation-1 mb-6 mt-10" :hide-default-footer="true">
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem2(item)">mdi-pencil</v-icon>
                <v-icon small @click="deleteItemStudent(item)">mdi-delete</v-icon>
              </template>
              <template v-slot:item.birth_date="{ item }">
                {{formatedDate(item.birth_date)}}
              </template>
            </v-data-table>

            <v-card-actions clas>
              <v-btn text>{{$t('actions.cancel')}}</v-btn>
              <v-spacer></v-spacer>

              <v-btn color="primary" @click="nextStep(2)">{{$t('actions.next')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-card>
            <v-card-title>Les classes</v-card-title>

            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Nom</th>
                    <th class="text-left">Prénom</th>
                    <th class="text-left">Date de naissance</th>
                    <th class="text-left">Année scolaire</th>
                    <th class="text-left">Classes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in students" :key="item.name">
                    <td>{{ item.last_name }}</td>
                    <td>{{ item.first_name }}</td>
                    <td>{{ formatedDate(item.birth_date) }}</td>
                    <td>{{schoolyear.name}}</td>
                    <td>
                      <v-select :items="classes" @change="changeClasse($event,item)" item-value="id" item-text="level">
                        <template v-slot:selection="{ item }">{{ item.level }} {{ item.schedule }}</template>
                        <template v-slot:item="data">{{ data.item.level }} - {{ data.item.schedule }} - {{data.item.nb_students}}/{{data.item.max_students}}</template>
                      </v-select>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

             <v-select style="display:none;" :items="computedItems" item-value="item.id" item-text="level">
              <template v-slot:selection="{ item }">{{ item.item.level }} {{ item.item.schedule }}</template>
              <template v-slot:item="data">{{ data.item.item.level }} - {{ data.item.item.schedule }} - {{data.item.item.nb_students}}/{{data.item.item.max_students}}</template>
            </v-select> 

            <v-card-actions clas>
              <v-btn text>{{$t('actions.cancel')}}</v-btn>
              <v-spacer></v-spacer>

              <v-btn color="primary" @click="nextStep(3)">{{$t('actions.next')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-card :loading="loading">
            <v-card-title>Résumé de l'inscription</v-card-title>
            <v-card-text>
              <p class="text--primary">Tuteur</p>
              <v-simple-table v-if="tutor">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Nom</th>
                      <th class="text-left">Prénom</th>
                      <th class="text-left">Communication</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ tutor.last_name }}</td>
                      <td>{{ tutor.first_name }}</td>
                      <td>+++{{ tutor.banking_communication}}+++</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-text>
              <p class="text--primary">Élèves</p>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Nom</th>
                      <th class="text-left">Prénom</th>
                      <th class="text-left">Date de naissance</th>
                      <th class="text-left">Année scolaire</th>
                      <th class="text-left">Classe</th>
                      <th class="text-left">Paiement</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in inscriptionsSaved" :key="item.id">
                      <td>{{ item.student.last_name }}</td>
                      <td>{{ item.student.first_name }}</td>
                      <td>{{ formatedDate(item.student.birth_date) }}</td>
                      <td>{{ item.school_year.name}}</td>
                      <td>{{ item.classe.level.name}} {{ item.classe.schedule.day}}</td>
                      <td>220€</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Total : {{prixTotal}} €</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions class="text-center">
              <!-- <v-btn color="blue darken-1" text @click="dialogPaiementCash = true">Payer cash</v-btn> -->
              <v-btn color="blue darken-1" text to="/school/1/inscription">Vers les inscriptions</v-btn>
              <v-spacer></v-spacer>
              <!-- <v-btn color="blue darken-1" text @click="inscriptionFinished = true">Payer par virement</v-btn> -->
              <v-btn color="blue darken-1" text @click="clearInscription()">Nouvelle inscription</v-btn>
            </v-card-actions>
            <v-dialog v-model="dialogPaiementCash">
              <v-card class="" :loading="loading">
                <v-card-title>Total à payer : {{prixTotal}} €</v-card-title>
                <v-card-text v-if="this.inscriptionsSaved.length == 1">Mode de paiement : paiement annuelle</v-card-text>
                <v-card-text v-if="this.inscriptionsSaved.length == 2">Mode de paiement : paiement en 2 fois</v-card-text>
                <v-card-text v-if="this.inscriptionsSaved.length >= 3">Mode de paiement : paiement mensuelle</v-card-text>
                <v-card-text>
                  <v-form ref="paiementForm" v-model="paiementValid">
                    <v-text-field :rules="[rules.required, rules.minSomme]" v-model="somme" label="Somme payée" hide-details="auto"></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn text>{{$t('actions.cancel')}}</v-btn>
                  <v-spacer></v-spacer>

                  <v-btn color="primary" :disabled="!paiementValid" @click="payer">Payer</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-card v-if="this.inscriptionFinished">
      <v-card-title>Inscrition réussi !</v-card-title>
      <v-card-actions>
        <v-btn to="/school/1">Vers Tableau de bord</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="clearInscription()">Nouvelle inscrption</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { APIServices } from "@/api/api";

export default {
  data() {
    return {
      paiementValid: false,
      rules: {
        required: value => !!value || "Required.",
        counter: value => value.length <= 20 || "Max 20 characters",
        minSomme: value => {
          var inscriptions = 0;
          if (this.inscriptionsSaved) {
            inscriptions = this.inscriptionsSaved.length;
          }
          console.log("value : " + value);
          console.log("inscriptions : " + inscriptions);
          if (inscriptions == 1) {
            return value == 200 || "Somme de 200€ exact";
          }
          if (inscriptions == 2) {
            return value >= 200 || "Somme min de 200€";
          }
          if (inscriptions >= 3) {
            return value >= 67 || "Somme min de 67€";
          } else {
            return true;
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      },
      inscriptionFinished: false,
      somme: null,
      dialogTutorTitle: "",
      dialogStudentTitle: "",
      dialogStudent: false,
      dialogPaiementCash: false,
      snackbar: false,
      snackbarCreate: false,
      dialogTutor: false,
      headers0: [
        { text: "Lastname", value: "last_name" },
        { text: "Firstname", value: "first_name" },
        { text: "Birth date", value: "birth_date" },
        { text: "Health situation", value: "health_situation" },
        { text: "Comments", value: "comments" },
        { text: "Actions", value: "actions" }
      ],
      student: null,
      students: [],
      schoolyear: null,
      lorem: "Lorem ipsum dolor sit amet, at aliquam vivendum vel, everti delicatissimi cu eos. Dico iuvaret debitis mel an, et cum zril menandri. Eum in consul legimus accusam. Ea dico abhorreant duo, quo illum minimum incorrupte no, nostro voluptaria sea eu. Suas eligendi ius at, at nemore equidem est. Sed in error hendrerit, in consul constituam cum.",
      loading: false,
      items: [],
      studentsSearched: [],
      search: null,
      search2: null,
      tutor: null,
      tutorbackup: {},
      e1: 1,
      steps: 4,
      vertical: false,
      altLabels: false,
      editable: true,
      tutors: [],
      classes: [],
      inscriptions: [],
      inscriptionsSaved: [],
      errors: [],
      error: null,
      modeTutor: null,
      file: null,
      nameRules: [v => !!v || "Champs obligatoire !"],
      editedIndex: -1,
      editedItem: {
        gender: "",
        last_name: "",
        first_name: "",
        birth_date: "",
        address: "",
        zip: "",
        city: "",
        email: "",
        mobile: ""
      },
      defaultItem: {
        gender: "",
        last_name: "",
        first_name: "",
        birth_date: "",
        address: "",
        zip: "",
        city: "",
        email: "",
        mobile: ""
      },
      genders: [
        {
          name: "Garçon",
          key: "male"
        },
        {
          name: "Fille",
          key: "female"
        }
      ]
    };
  },
  computed: {
    computedItems() {
      return this.classes.map(item => {
        var disabled = true;
        if (item.nb_students >= item.max_students) {
          disabled = true;
        } else {
          disabled = false;
        }
        item.disabled = disabled;
        console.log(item);
        return {
          item: item,
          disabled: disabled
        };
      });
    },
    prixTotal() {
      console.log(this.inscriptionsSaved.length);
      console.log(this.inscriptionsSaved.length * 220);
      return this.inscriptionsSaved.length * 220;
    },
    tutorLabelStep() {
      if (this.tutor) {
        return this.tutor.name;
      } else {
        return "Tuteur";
      }
    },
     headers() {
      return [
        { text: this.$t("lastname"), value: "last_name" },
        { text: this.$t("firstname"), value: "first_name" },
        { text: this.$t("birthdate"), value: "birth_date" },
        { text: this.$t("health_situation"), value: "health_situation" },
        { text: this.$t("comments"), value: "comments" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    }
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
    search(val) {
      val && val !== this.tutor && this.searchTutor(val);
    },
    search2(val) {
      val && val !== this.student && this.searchStudents(val);
    },
    dialogTutor(val) {
      val || this.close();
    }
  },
  created() {
    this.loadSchoolYear();
    this.loadClasses();
  },
  methods: {
    formatedDate(date) {
      return this.$moment(date).format("Do MMMM YYYY");
    },
    clearInscription() {
      this.tutor = null;
      this.student = null;
      this.students = [];
      this.items = [];
      this.studentsSearched = [];
      this.inscriptions = [];
      this.inscriptionsSaved = [];
      this.inscriptionFinished = false;
      this.somme = 0;
      this.nextStep(4);
    },
    payer() {
      if (this.$refs.paiementForm.validate()) {
        var total = this.inscriptionsSaved.length * 220;
        console.log("total à payer : " + total);
        console.log("somme payée : " + this.somme);
        this.loading = true;

        var amount = this.somme / this.inscriptionsSaved.length;
        var start_date = null;
        var end_date = null;
        var value_date = null;
        if (total > this.somme) {
          // Alors facilité de paiement

          if (this.inscriptionsSaved.length == 1) {
            start_date = "2020-09-01";
            end_date = "2021-06-01";
          }
          if (this.inscriptionsSaved.length == 2) {
            start_date = "2020-09-01";
            end_date = "2021-01-15";
          }
          if (this.inscriptionsSaved.length >= 3) {
            start_date = "2020-09-01";
            end_date = "2020-10-01";
          }
        } else {
          // Sinon Paiement total
          start_date = "2020-09-01";
          end_date = "2021-06-01";
        }
        for (var key in this.inscriptionsSaved) {
          console.log("inscription key : " + key + " et inscription : " + this.inscriptionsSaved[key]);
          console.log(this.inscriptionsSaved[key].id);

          console.log("start_date : " + start_date);
          console.log("end_date : " + end_date);
          console.log("amount : " + amount);

          value_date = "2021-06-01";

          APIServices.post(`/tutors/${this.tutor.id}/students/${this.inscriptionsSaved[key].student_id}/inscriptions/${this.inscriptionsSaved[key].id}/contributions`, {
            start_date: start_date,
            end_date: end_date,
            amount: amount,
            value_date: value_date
          })
            .then(response => {
              console.log(response.data);
              this.inscriptionFinished = true;
              this.loading = false;
              this.dialogPaiementCash = false;
            })
            .catch(e => {
              this.error = e.response.data;
              console.log("echec de création d inscription");
              console.log(e.response.data);
              this.inscriptionFinished = false;
              this.loading = false;
              this.dialogPaiementCash = false;
            });
        }
      }
      //this.loading = false;
    },
    addTutor() {
      console.log("add tutor");
      this.dialogTutorTitle = "Ajout d'un tuteur";

      this.modeTutor = "add";
      this.dialogTutor = true;
    },
    addStudent() {
      console.log("add student");
      this.dialogStudentTitle = "Ajout d'un élève";
      this.dialogStudent = true;
    },
    updateTutor() {
      console.log("update tutor");

      this.modeTutor = "edit";

      this.dialogTutorTitle = "Modification d'un tuteur";
      this.dialogTutor = true;
    },
    editItem(item) {
      console.log("item.id = " + item.id);
      this.editedIndex = item.id;
      this.editedItem = Object.assign({}, item);
      this.dialogTutor = true;
      this.dialogTutorTitle = "Modification d'un tuteur";
    },

    editItem2(item) {
      this.editedIndex = this.students.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogStudent = true;
      this.dialogStudentTitle = "Modification d'un élève";
    },
    loadSchoolYear() {
      console.log("loading schoolyear");
      this.loading = true;
      APIServices.get("/schoolyears?current=1")
        .then(response => {
          console.log(response.data[0]);
          /*this.schoolyears = Object.keys(response.data).map(key => {
            return response.data[key];
          });*/
          this.schoolyear = response.data[0];
          this.loading = false;
        })
        .catch(e => {
          this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    },
    loadClasses() {
      console.log("loading classes");
      this.loading = true;
      APIServices.get("/classes")
        .then(response => {
          console.log(response);
          this.classes = Object.keys(response.data).map(key => {
            return response.data[key];
          });
          this.loading = false;
        })
        .catch(e => {
          this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    },
    loadStudents() {
      console.log("loading student with tutor : " + this.tutor.id);
      this.loading = true;
      APIServices.get("/tutors/" + this.tutor.id + "/students")
        .then(response => {
          console.log(response);
          this.students = Object.keys(response.data).map(key => {
            return response.data[key];
          });
          this.loading = false;
        })
        .catch(e => {
          this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    },
    searchStudents(v) {
      console.log("searchStudent with : " + v);
      this.loading = true;

      APIServices.get("/students?search=" + v)
        .then(response => {
          this.studentsSearched = response.data.data;
          console.log(this.studentsSearched);
          this.loading = false;
        })
        .catch(e => {
          this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    },
    searchTutor(v) {
      this.loading = true;

      APIServices.get("/tutors?search=" + v)
        .then(response => {
          this.items = response.data.data;
          console.log(this.items);
          this.loading = false;
        })
        .catch(e => {
          this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    },
    changeClasse(selectedClasseId, student) {
      console.log("add inscription with studentId : " + student.id + " and  classeId : " + selectedClasseId);
      this.inscriptions[student.id] = { student_id: student.id, school_year_id: this.schoolyear.id, classe_id: selectedClasseId };
      console.log(this.inscriptions);
    },
    createInscriptions() {
      this.loading = true;
      for (var key in this.inscriptions) {
        console.log("inscription key : " + key + " et inscription : " + this.inscriptions[key]);
        console.log(this.inscriptions[key].id);

        APIServices.post(`/inscriptions`, this.inscriptions[key])
          .then(response => {
            console.log(response.data);
            this.inscriptions.splice(key, 1);
            this.inscriptions[key] = Object.assign({}, response.data);

            var keys = Object.keys(response.data);
            var key = keys[0];
            this.inscriptionsSaved.push(response.data[key]);

            console.log("inscription créée");
            // OK
            var filtered = this.inscriptions.filter(function(el) {
              return el != null;
            });

            console.log(filtered);
            this.loading = false;
          })
          .catch(e => {
            this.error = e.response.data;
            console.log("echec de création d inscription");
            console.log(e.response.data);
            this.loading = false;
          });
      }
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
      if (this.e1 === 2) {
        //this.loadSchoolYear();
        this.loadStudents();
      }
      if (this.e1 === 3) {
        this.loadClasses();
      }
      if (this.e1 === 4) {
        this.createInscriptions();
        this.snackbarCreate = true;
      }
    },
    close() {
      console.log("close");
      this.dialogTutor = false;
      this.dialogStudent = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
      this.file = null;
      this.error = null;
    },

    clear() {
      //this.editedItem = Object.assign({}, this.defaultItem);
      //this.editedIndex = -1;
      this.file = null;
    },
    addStudentToTutor() {
      console.log("addStudentToTutor");
      APIServices.patch(`/tutors/${this.tutor.id}/students/${this.student.id}`, this.student)
        .then(response => {
          this.snackbar = true;
          console.log(response);
          this.students.push(response.data);
          this.student = null;
          //this.loadStudents();
          this.loading = false;
          this.close();
        })
        .catch(e => {
          this.error = e.response.data;
          console.log(e.response.data);
          this.loading = false;
        });
    },
    saveStudent() {
      this.loading = true;
      console.log(this.editedIndex);
      if (this.editedIndex > -1) {
        console.log("Mode edition");
        console.log(`/tutors/${this.tutor.id}/students/` + this.editedItem.id);

        this.editedItem.birth_date = this.$moment(this.editedItem.birth_date, "DD/MM/YYYY").format("YYYY-MM-DD");

        APIServices.put(`/tutors/${this.tutor.id}/students/` + this.editedItem.id, this.editedItem)
          .then(response => {
            this.snackbar = true;
            console.log(response.data);
            //this.student = response.data;
            Object.assign(this.students[this.editedIndex], this.editedItem);
            this.loading = false;
            console.log("before close");
            this.close();
          })
          .catch(e => {
            console.log("before catch");
            this.error = e.response.data;
            console.log(e.response.data);
            this.loading = false;
          });
      } else {
        console.log("Mode ajout");
        this.editedItem.birth_date = this.$moment(this.editedItem.birth_date, "DD/MM/YYYY").format("YYYY-MM-DD");
        APIServices.post(`/tutors/${this.tutor.id}/students`, this.editedItem)
          .then(response => {
            this.snackbar = true;
            console.log(response);
            this.students.push(response.data);
            //this.loadStudents();
            this.loading = false;
            this.close();
          })
          .catch(e => {
            this.error = e.response.data;
            console.log(e.response.data);
            this.loading = false;
          });
      }
    },

    deleteItemStudent(item) {
      confirm("Are you sure you want to delete this item?") && this.removeStudent(item);
    },
    removeStudent(item) {
      this.loading = true;
      APIServices.delete(`/tutors/${this.tutor.id}/students/` + item.id)
        .then(response => {
          console.log(response);
          this.snackbar = true;

          const index = this.students.indexOf(item);
          this.students.splice(index, 1);

          this.loading = false;
        })
        .catch(e => {
          this.error = e.response.data;
          console.log(e.response.data);
          this.loading = false;
        });
    },
    save() {
      this.loading = true;
      if (this.editedItem.id != null) {
        console.log("Mode edition");
        this.editedItem.birth_date = null;
        APIServices.put(`/tutors/` + this.editedItem.id, this.editedItem)
          .then(response => {
            this.snackbar = true;
            console.log(response);
            this.tutor = response.data;
            this.loading = false;
            this.close();
          })
          .catch(e => {
            this.errors.push(e.response.data);
            this.error = e.response.data;
            console.log(e.response.data);
            this.loading = false;
          });
      } else {
        console.log("Mode ajout");
        //this.$emit("tutorAdded", this.editedItem);
        //this.teachers.push(this.editedItem);
        this.editedItem.birth_date = null;
        APIServices.post(`/tutors`, this.editedItem)
          .then(response => {
            this.snackbar = true;
            console.log(response);
            this.tutor = response.data;
            this.loading = false;
            this.close();
          })
          .catch(e => {
            this.errors.push(e.response.data);
            this.error = e.response.data;
            console.log(e.response.data);
            this.loading = false;
          });
      }
    },

    loadData(file) {
      let self = this;
      if (file != undefined) {
        var reader = new FileReader();
        reader.onload = function(data) {
          data = reader.result;
          var xmlDoc;
          var parser;
          parser = new DOMParser();
          xmlDoc = parser.parseFromString(data, "text/xml");
          self.editedItem.gender = xmlDoc.getElementsByTagName("identity")[0].attributes["gender"].nodeValue;
          let dateofbirth = xmlDoc.getElementsByTagName("identity")[0].attributes["dateofbirth"].nodeValue;
          self.editedItem.birth_date = self.$moment(dateofbirth, "YYYYMMDD").format("YYYY-MM-DD");
          self.editedItem.last_name = xmlDoc.getElementsByTagName("name")[0].childNodes[0].nodeValue;
          self.editedItem.first_name = xmlDoc.getElementsByTagName("firstname")[0].childNodes[0].nodeValue;
          self.editedItem.address = xmlDoc.getElementsByTagName("streetandnumber")[0].childNodes[0].nodeValue;
          self.editedItem.zip = xmlDoc.getElementsByTagName("zip")[0].childNodes[0].nodeValue;
          self.editedItem.city = xmlDoc.getElementsByTagName("municipality")[0].childNodes[0].nodeValue;
          // self.editedItem.photo = "data:image/jpg;base64," + xmlDoc.getElementsByTagName("photo")[0].childNodes[0].nodeValue;

          //document.getElementById('eid_pict').src = "data:image/jpg;base64,"+pict;
        };
        reader.readAsText(file, "UTF-8");
      }
    }
  }
};
</script>

<style>
.theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot {
  background-color: grey;
}

.theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot > .v-select__slot {
  color: white;
}

i.v-icon.notranslate.mdi.mdi-menu-down.theme--light.primary--text {
  color: white !important;
}

button.v-icon.notranslate.v-icon--link.mdi.mdi-close.theme--light.primary--text {
  color: white !important;
}
</style>
